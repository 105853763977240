<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <FormDialog
        :dialog="dialog"
        :title="'Neue Location anlegen'"
        :values="createFields"
        :successButton="'Erstellen'"
        :icon="'mdi mdi-map-marker-radius-outline'"
        :headLines="[
          {text: 'Kontakt', position: 6},
          {text: 'Adresse', position: 8},
            ]"
        :cut="6"
        @hideDialog="hideDialog"
        @emitNewData="createLocation"
    ></FormDialog>
  </div>
</template>
<script>
import FormDialog from "@/components/dialog/FormDialogNew";
import {mapGetters} from "vuex";
import {msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "CreateLocation",
  components: {
    FormDialog,
    Message
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      stilNames: 'stilNames',
    })
  },
  data() {
    return {
      message: msgObj(),
      createFields: this.locationCreateFields
    }
  },
  props: {
    dialog: Boolean,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    locationCreateFields: {
      type: Array,
      default() {
        return [
          {
            title: 'Name',
            value: '',
            id: 0,
            type: 'string'
          },
          {
            title: 'Stil',
            value: '',
            id: 1,
            type: 'multipleAutoComplete',
            items: this.stilNames,
          },
          {
            title: 'Preisklasse',
            value: null,
            id: 2,
            type: 'autoComplete',
            items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}]
          },
          {
            title: 'Max. Personenanzahl',
            value: null,
            id: 3,
            type: 'string'
          },
          {
            title: 'Fläche Innen in m²',
            value: null,
            id: 4,
            type: 'number'
          },
          {
            title: 'Fläche Aussen in m²',
            value: null,
            id: 5,
            type: 'number'
          },
          {
            title: 'Telefon',
            value: '',
            id: 6,
            type: 'string'
          },
          {
            title: 'E-Mail',
            value: '',
            id: 7,
            type: 'string'
          },
          {
            title: 'Straße',
            value: '',
            id: 8,
            type: 'string'
          },
          {
            title: 'Hausnummer',
            value: '',
            id: 9,
            type: 'string'
          },
          {
            title: 'PLZ',
            value: '',
            id: 10,
            type: 'string'
          },
          {
            title: 'Ort',
            value: '',
            id: 11,
            type: 'string'
          },
        ]
      }
    }
  },
  mounted() {
    this.$store.dispatch('settings/getStil', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = {
        show: true,
        text: err,
        color: "error",
      }
      setTimeout(() => this.message.show = false, 5000);
    }).then(()=>{
      this.createFields =[
            {
              title: 'Name',
              value: '',
              id: 0,
              type: 'string'
            },
            {
              title: 'Stil',
              value: '',
              id: 1,
              type: 'multipleAutoComplete',
              items: this.stilNames,
            },
            {
              title: 'Preisklasse',
              value: null,
              id: 2,
              type: 'autoComplete',
              items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}]
            },
            {
              title: 'Max. Personenanzahl',
              value: null,
              id: 3,
              type: 'string'
            },
            {
              title: 'Fläche Innen in m²',
              value: null,
              id: 4,
              type: 'number'
            },
            {
              title: 'Fläche Aussen in m²',
              value: null,
              id: 5,
              type: 'number'
            },
            {
              title: 'Telefon',
              value: '',
              id: 6,
              type: 'string'
            },
            {
              title: 'E-Mail',
              value: '',
              id: 7,
              type: 'string'
            },
            {
              title: 'Straße',
              value: '',
              id: 8,
              type: 'string'
            },
            {
              title: 'Hausnummer',
              value: '',
              id: 9,
              type: 'string'
            },
            {
              title: 'PLZ',
              value: '',
              id: 10,
              type: 'string'
            },
            {
              title: 'Ort',
              value: '',
              id: 11,
              type: 'string'
            },
          ]

    })
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    createLocation(newValues) {
      let newLocation = this.getLocationFromInput(newValues)
      console.log(newLocation);
      this.$store.dispatch('locations/createLocation', {
        newLocation,
        uid: this.user.id,
        openAfterCreate: this.openAfterCreate
      }).then(() => {
            this.hideDialog()
            this.message = success('Location wurde erfolgreich angelegt.')
            this.$emit('createLocation',{})
          }
      )

    },
    getLocationFromInput(newValues) {
      let newLocation = {
        name: '',
        groesseAussen: null,
        groesseInnen: null,
        stil: '',
        maxPersonen: null,
        street: '',
        houseNumber: '',
        plz: '',
        city: '',
        phone: '',
        email: '',
        priceClass: null
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Name':
            newLocation.name = value.value
            break;
          case 'Fläche Aussen in m²':
            newLocation.groesseAussen = value.value
            break;
          case 'Fläche Innen in m²':
            newLocation.groesseInnen = value.value
            break;
          case 'Stil':
            newLocation.stil = value.value
            break;
          case 'Max. Personenanzahl':
            newLocation.maxPersonen = value.value
            break;
          case 'Straße':
            newLocation.street = value.value
            break;
          case 'Hausnummer':
            newLocation.houseNumber = value.value
            break;
          case 'PLZ':
            newLocation.plz = value.value
            break;
          case 'Ort':
            newLocation.city = value.value
            break;
          case 'Telefon':
            newLocation.phone = value.value
            break;
          case 'E-Mail':
            newLocation.email = value.value
            break;
          case 'Preisklasse':
            newLocation.priceClass = value.value
            break;
        }
      }
      return newLocation
    },
  },
}
</script>
